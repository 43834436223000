class SeasonFetcherService {
  private fetchingPromise = {
    league: {},
    team: {},
    player: {},
  };

  constructor(
    private $http,
    private $q,
    private SCConfiguration,
    private SeasonService,
  ) {}

  getSeasons(type: 'league' | 'team' | 'player', id: string) {
    if (!id) {
      throw new Error('Id is required');
    }

    let fetchPromise = this.fetchingPromise[type][id];

    if (!this.fetchingPromise[type][id]) {
      let endpoint = '';

      switch (type) {
        case 'league':
          endpoint = `${this.SCConfiguration.getEndpoint()}/api/leagues/${id}/seasons`;
          break;
        case 'team':
          endpoint = `${this.SCConfiguration.getEndpoint()}/api/teams/${id}/seasons`;
          break;
        case 'player':
          endpoint = `${this.SCConfiguration.getEndpoint()}/api/players/${id}/seasons`;
          break;
        default:
          return;
      }
      fetchPromise = this.$http.get(endpoint);
      this.fetchingPromise[type][id] = fetchPromise;
    }

    return fetchPromise.then((response) => {
      const seasons = response.data;

      const seasonId = this.SeasonService.getSeasonId();

      if (seasonId && seasons.some((s) => s.id === seasonId)) {
        // do nothing
      } else {
        // Default to most recent season
        const firstSeason = seasons[0];

        if (firstSeason) {
          this.SeasonService.setSeasonId(firstSeason.id);
        }
      }

      return seasons;
    });
  }
}

angular.module('app.common').service('SeasonFetcherService', SeasonFetcherService);
