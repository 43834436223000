/* globals localStorage */
/* globals sessionStorage */

(function () {
  var TOKEN_KEY = 'app:token';
  var RETURN_URL_KEY = 'app:login-return-url';

  var LEGACY_LANGUAGE_TRANSLATION_TABLE = {
    ru_Russian: 'ru_RU',
    en_English: 'en_US',
    de_German: 'de_DE',
  };

  // TODO: it's time to refactor me!

  function UserService($http, $rootScope, $q, $translate, SCConfiguration, jwtHelper) {
    var currentToken = null;
    var currentUser = null;
    var fetchPromise = null;
    var storage = null;
    var loginEndpoint = '/api/users/login';
    var self = this;

    this.fetchUser = fetchUser;
    this.getUser = getUser;
    this.getPromise = getPromise;

    this.login = login;
    this.logout = logout;
    this.save = save;
    this.clearLocalSession = clearLocalSession;

    this.adminLogin = adminLogin;
    this.dropAdminAccess = dropAdminAccess;
    this.isLoggedInAsAdmin = isLoggedInAsAdmin;

    this.getAvailableAccounts = getAvailableAccounts;
    this.getActiveAccount = getActiveAccount;
    this.switchToAccount = switchToAccount;

    this.getToken = getToken;
    this.setToken = setToken;
    this.getDecodedToken = getDecodedToken;

    this.isUserLoggedIn = isUserLoggedIn;
    this.isUserInvisible = isUserInvisible;
    this.setReturnUrl = setReturnUrl;
    this.getReturnUrl = getReturnUrl;
    this.clearReturnUrl = clearReturnUrl;

    this.setLoginEndpoint = setLoginEndpoint;

    this.listLoginSessions = listLoginSessions;
    this.terminateSession = terminateSession;
    this.terminateAllOtherSessions = terminateAllOtherSessions;

    this.createUser = createUser;

    chooseStorage();
    loadToken();
    fetchPromise = fetchUser();

    function chooseStorage() {
      if (sessionStorage.getItem(TOKEN_KEY)) {
        storage = sessionStorage;
      } else {
        storage = localStorage;
      }
    }

    function loadToken() {
      currentToken = storage.getItem(TOKEN_KEY);
    }

    function fetchUser() {
      if (!currentToken) {
        return $q.when();
      }

      fetchPromise = $http.get(SCConfiguration.getEndpoint() + '/api/me').then(receiveUser);

      return fetchPromise;

      function receiveUser(response) {
        currentUser = Object.create({
          $save: function () {
            save(this);
          },
        });

        _.assign(currentUser, response.data);

        $rootScope.$broadcast('userDataUpdated', response.data);
        $translate.use(
          LEGACY_LANGUAGE_TRANSLATION_TABLE[_.get(currentUser, 'settings.language')] ||
            _.get(currentUser, 'settings.language')
        );

        return currentUser;
      }
    }

    function getActiveAccount() {
      if (currentUser) {
        return currentUser.account;
      }
    }

    function getAvailableAccounts() {
      if (currentUser) {
        return $q.when(currentUser.accounts);
      }

      return $http
        .get(SCConfiguration.getEndpoint() + '/api/users/accounts')
        .then(function (response) {
          return response.data;
        });
    }

    function switchToAccount(account) {
      return $http
        .post(SCConfiguration.getEndpoint() + '/api/users/account', account)
        .then(function (response) {
          $rootScope.$broadcast('user:account:change');
          setToken(response.data.token);
          return fetchUser();
        });
    }

    function login(identity, password, persistent) {
      var credentials = {
        login: identity,
        password: password,
      };

      if (!identity || !password) {
        return $q.reject(new Error('Invalid input'));
      }

      return $http
        .post(SCConfiguration.getEndpoint() + loginEndpoint, credentials)
        .then(function (response) {
          if (persistent) {
            storage = localStorage;
          } else {
            storage = sessionStorage;
          }

          setToken(response.data.token);
        });
    }

    function logout() {
      return $http.post(SCConfiguration.getEndpoint() + '/api/users/logout').then(function () {
        clearLocalSession();
        $rootScope.$broadcast('logout');
      });
    }

    function clearLocalSession() {
      currentToken = null;
      currentUser = null;

      storage.removeItem(TOKEN_KEY);

      chooseStorage();
    }

    function adminLogin(password) {
      var credentials = {
        password: password,
      };

      if (!password) {
        return $q.reject(new Error('Invalid input'));
      }

      if (!currentToken) {
        throw new Error('AdminLogin must be done after login');
      }

      return $http
        .post(SCConfiguration.getEndpoint() + '/api/users/admin-login', credentials)
        .then(function (response) {
          setToken(response.data.token);
        });
    }

    function dropAdminAccess() {
      if (isUserInvisible()) {
        return $q.resolve(function () {
          $rootScope.$broadcast('user:admin:logout');
        });
      }

      return $http
        .post(SCConfiguration.getEndpoint() + '/api/users/admin-logout')
        .then(function (response) {
          setToken(response.data.token);
          $rootScope.$broadcast('user:admin:logout');
          return fetchUser();
        });
    }

    function getUser() {
      return currentUser;
    }

    function getPromise() {
      return fetchPromise;
    }

    function getToken() {
      return currentToken;
    }

    function getDecodedToken() {
      return jwtHelper.decodeToken(currentToken);
    }

    function setToken(token) {
      if (jwtHelper.isTokenExpired(token)) {
        return;
      }

      currentToken = token;
      storage.setItem(TOKEN_KEY, token);
    }

    function isUserLoggedIn() {
      return !!currentToken;
    }

    function isLoggedInAsAdmin() {
      return currentToken && jwtHelper.decodeToken(currentToken).admin;
    }

    function setReturnUrl(value) {
      localStorage.setItem(RETURN_URL_KEY, value);
    }

    function getReturnUrl() {
      return localStorage.getItem(RETURN_URL_KEY);
    }

    function clearReturnUrl() {
      localStorage.removeItem(RETURN_URL_KEY);
    }

    function save(updatedData) {
      var updateModel;

      if (!updatedData) {
        throw new Error('update is not defined');
      }

      if (updatedData.id) {
        updateModel = updatedData;
      } else {
        updateModel = angular.extend(currentUser, updatedData);
      }

      return $http.put(
        SCConfiguration.getEndpoint() + '/api/users/' + currentUser._id,
        updateModel
      );
    }

    function setLoginEndpoint(endpoint) {
      loginEndpoint = endpoint;
    }

    function listLoginSessions() {
      return $http.get(SCConfiguration.getEndpoint() + '/api/users/logins').then(function (res) {
        return res.data;
      });
    }

    function terminateSession(tokenId) {
      return $http
        .delete(SCConfiguration.getEndpoint() + '/api/users/logins/' + tokenId)
        .then(function (res) {
          return res.data;
        });
    }

    function terminateAllOtherSessions() {
      return $http.delete(SCConfiguration.getEndpoint() + '/api/users/logins').then(function (res) {
        return res.data;
      });
    }

    function isUserInvisible() {
      return !!currentToken && !!jwtHelper.decodeToken(currentToken).invisible;
    }

    function createUser(dto) {
      return $http.post(SCConfiguration.getEndpoint() + '/api/users', dto).then(function (res) {
        return res.data;
      });
    }
  }

  angular.module('app.common').service('UserService', UserService);
})();
